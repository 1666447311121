<template>
	<div class="article">
    <span v-if="!loader">
      <h2 v-html="title" />
      <p v-html="content" />
    </span>
    <Loader v-else />
  </div>
</template>


<script>
  export default {
    components: {
      Loader: () => import('../components/Loader.vue'),
    },  
    name: "Article",
    data () {
      return {
        title: null,
        content: null,
        loader: true
      }
    },
    beforeCreate() { 
        this.$http
          .get('https://api.opci-ethnodoc.fr/wp-json/wp/v2/posts/'+this.$route.params.id  )
          .then(response => (this.title = response.data.title.rendered,
                             this.content = response.data.content.rendered,
                             this.loader = false ))
      }
    }
</script>

<style>
	h2
	{
		margin-bottom: 30px;
	}

</style>